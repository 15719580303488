import { TApplication } from "interfaces/application-v2";

import { IApplicantData, IUserProfile } from "../../hooks";
import { TPages, TUserKind } from "./types";

type TUser = { kind: TUserKind; value: boolean };

const pagesWithShariah: TPages[] = ["homepage", "get-approved", "you-are-on-the-waitlist"];
const pagesWithSelfEmployment: TPages[] = ["application-process"];

export const getIsShariah = (profile: Partial<IUserProfile> | null, applicationV2: TApplication | null): boolean => {
    if (profile?.application?.signUpReason.shariah) {
        return true;
    }

    if (applicationV2?.signUpReason.shariah) {
        return true;
    }

    return false;
};

export const getIsSelfEmployedApplication = (
    profile: Partial<IUserProfile> | null,
    applicationV2: TApplication | null | undefined,
): boolean => {
    const isProfileSelfEmployed = profile?.applicants?.some(
        (applicant: IApplicantData) => applicant.jobStatus === "Self-employment",
    );

    const isApplicationSelfEmployed = applicationV2
        ? applicationV2.leadApplicant.employmentStatus === "self_employed" ||
          applicationV2.secondApplicant.employmentStatus === "self_employed"
        : false;

    return isProfileSelfEmployed || isApplicationSelfEmployed;
};

// Users can have multiple kinds set simultaneously, we want to pick the most relevant one
// depending on the page.
export const getUserKind = (
    profile: Partial<IUserProfile> | null,
    applicationV2: TApplication | null,
    page: TPages,
) => {
    const generic: TUser = { kind: "generic", value: true };
    const shariah: TUser = { kind: "shariah", value: getIsShariah(profile, applicationV2) };
    const selfEmployed: TUser = {
        kind: "self-employed",
        value: getIsSelfEmployedApplication(profile, applicationV2) || false,
    };

    let priorityOrderBasedOnPage = [generic];

    if (pagesWithShariah.includes(page)) {
        priorityOrderBasedOnPage = [shariah, selfEmployed, generic];
    }

    if (pagesWithSelfEmployment.includes(page)) {
        priorityOrderBasedOnPage = [selfEmployed, shariah, generic];
    }

    const userKind = priorityOrderBasedOnPage.find((reason) => reason.value)!.kind;

    return userKind;
};
