import React from "react";

import { PageWidth, ShortParagraphCss } from "@wayhome-uk/design-system";
import { IBaseComponent } from "@wayhome-uk/types";
import { customWindow } from "interfaces";
import { Link } from "react-router-dom";
import styled from "styled-components";

import { IconFacebook } from "components/icons/icon-facebook";
import { IconInstagram } from "components/icons/icon-instagram";
import { IconLinkedIn } from "components/icons/icon-linkedin";
import { IconPRSLogo } from "components/icons/icon-prs-logo";
import { IconTwitter } from "components/icons/icon-twitter";
import { routes } from "config/routes.js";
import { NewLongParagraph } from "config/theme";
import { useCookieFirst } from "hooks/use-cookie-first";

export interface Props extends IBaseComponent {
    links: FooterLink[];
}

export interface FooterLink {
    url: string;
    rel?: string;
    target?: string;
    title: string;
    isExternal?: boolean;
}

const renderLink = (link: FooterLink, index: number) => {
    if (link.isExternal) {
        return (
            <ListItem key={index}>
                <LinkText as="a" title={link.title} href={link.url} target={link.target} rel={link.rel}>
                    {link.title}
                </LinkText>
            </ListItem>
        );
    }

    return (
        <ListItem key={index}>
            <LinkText title={link.title} to={link.url}>
                {link.title}
            </LinkText>
        </ListItem>
    );
};

export const RouterFooter = ({ className, links }: Props) => {
    const context = useCookieFirst();

    return (
        <Wrapper data-test-id="footer" className={className}>
            <PageWidth>
                <SocialLinks>
                    Find us on
                    <a href="https://www.facebook.com/WayhomeUK" target="_blank" rel="noopener noreferrer">
                        <IconFacebook />
                    </a>
                    <a href="https://twitter.com/wayhomeuk" target="_blank" rel="noopener noreferrer">
                        <IconTwitter />
                    </a>
                    <a href="https://www.instagram.com/wayhome_uk" target="_blank" rel="noopener noreferrer">
                        <IconInstagram />
                    </a>
                    <a href="https://www.linkedin.com/company/wayhomeuk" target="_blank" rel="noopener noreferrer">
                        <IconLinkedIn />
                    </a>
                </SocialLinks>
                <TopContainer>
                    <Title>
                        Got any questions?{" "}
                        <LinkText to={routes.marketing.faqs.path} target="_blank" rel="noopener noreferrer">
                            Check out our FAQs
                        </LinkText>{" "}
                        or{" "}
                        <IntercomInlineLink type="button" onClick={() => customWindow.Intercom("show")}>
                            chat to us through Live Chat
                        </IntercomInlineLink>
                    </Title>
                    <LinksList data-test-id="footer-links">
                        {links.map((link, index) => renderLink(link, index))}
                        <ListItem>
                            <LinkText as="button" title="Cookie Settings" onClick={() => context.openPanel()}>
                                Cookie Settings
                            </LinkText>
                        </ListItem>
                    </LinksList>
                </TopContainer>
                <TextWrapper>
                    <ColumnOne>
                        <Paragraph>
                            Unmortgage Ltd (registered in England and Wales, Company No. 10395239) and its subsidiaries
                            comprise the Unmortgage Group. Our registered office is at 9 Appold St, London, EC2A 2AP.
                        </Paragraph>
                        <Paragraph>Wayhome is a trading name of the Unmortgage Group.</Paragraph>
                        <Paragraph>
                            Unmortgage Arrangers Ltd, a subsidiary of Unmortgage Ltd (registered in England and Wales,
                            Company No. 11842567), is authorised and regulated by the Financial Conduct Authority (Firm
                            Reference Number 838366) for arranging and administering insurance policies only. Unmortgage
                            Arrangers Ltd is a distributor of Modulr FS Limited, a company registered in England and
                            Wales with company number 09897919, which is authorized and regulated by the Financial
                            Conduct Authority as an Electronic Money Institution (Firm Reference Number: 900573) for the
                            issuance of electronic money and payment services. Your account and related payment services
                            are provided by Modulr FS Limited. Whilst Electronic Money products are not covered by the
                            Financial Services Compensation Scheme (FSCS) your funds will be held in one or more
                            segregated accounts and safeguarded in line with the{" "}
                            <LinkText
                                href="https://www.legislation.gov.uk/uksi/2011/99/part/3/crossheading/safeguarding/made?view=plain#:~:text=Safeguarding%20option%201&text=%E2%80%94(1)%20An%20electronic%20money,other%20funds%20that%20it%20holds.&text=(b)invest%20the%20relevant%20funds,account%20with%20an%20authorised%20custodian."
                                rel="noopener noreferrer"
                                target="_blank"
                                as="a"
                            >
                                Electronic Money Regulations 2011
                            </LinkText>
                            . You can also download{" "}
                            <LinkText
                                href="https://storage.googleapis.com/unmortgage-static/MFSL-Introduced-Client-Terms.pdf"
                                rel="noopener noreferrer"
                                target="_blank"
                                as="a"
                            >
                                Modulr FS Limited’s terms and conditions
                            </LinkText>
                            .{" "}
                        </Paragraph>
                    </ColumnOne>
                    <ColumnTwo>
                        <Paragraph>
                            No other aspect of the Unmortgage Group’s business falls within the scope of financial
                            services regulation. This means you do not have any of the protections under the FCA rules
                            and do not have any cover from the Financial Ombudsman Scheme or the Financial Services
                            Compensation Scheme in relation to your interest in an LLP. Customers should ensure they
                            understand what this means before they use the Wayhome product.
                        </Paragraph>
                        <Paragraph>
                            For full details relating to our regulatory position please see our&nbsp;
                            <LinkText as="a" href={routes.misc.regulatoryInformation.path}>
                                Regulatory Information Page
                            </LinkText>
                            .
                        </Paragraph>
                        <Paragraph>
                            Wayhome Property Management Ltd, a subsidiary of Unmortgage Ltd, is a member of The Property
                            Redress Scheme (membership number PRS025713), which covers activities in relation to the
                            purchase of the home, the setting up and management of tenancy and the management of
                            maintenance of the home.
                        </Paragraph>
                        <Paragraph>
                            Wayhome Nominees Ltd (registered in England and Wales, Company No. 13085562) is a subsidiary
                            of Unmortgage Ltd.
                        </Paragraph>
                    </ColumnTwo>
                </TextWrapper>

                <LogosWrapper>
                    <a
                        href="https://storage.googleapis.com/unmortgage-static/PRS-MemberShip-Certificate.pdf"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        <IconPRSLogo />
                    </a>
                </LogosWrapper>
            </PageWidth>
        </Wrapper>
    );
};

const Wrapper = styled.div`
    background: ${({ theme }) => theme.primary800};
    width: 100%;
`;

const SocialLinks = styled.div`
    position: relative;
    display: flex;
    align-items: center;
    color: ${({ theme }) => theme.neutral0};
    padding-top: 1.5rem;
    padding-bottom: 1rem;
    padding-left: 0;
    font-weight: 500;
    line-height: 1.6;

    @media (min-width: ${({ theme }) => theme.mediumMinWidth}) {
        font-size: ${({ theme }) => theme.type18};
        justify-content: center;
        padding-bottom: 1.5rem;
    }

    > * {
        margin-left: 1rem;
        display: flex;
    }

    ::after {
        position: absolute;
        content: "";
        background-color: ${({ theme }) => theme.primary50};
        width: 100%;
        height: 1px;
        bottom: 0;
    }
`;

const TopContainer = styled.div`
    display: flex;
    flex-direction: column;
    padding: ${({ theme }) => theme.spacing16} 0 ${({ theme }) => theme.spacing16};
    border-bottom: 1px solid ${({ theme }) => theme.neutral0};

    @media (min-width: ${({ theme }) => theme.mediumMinWidth}) {
        padding: ${({ theme }) => theme.spacing24} 0 ${({ theme }) => theme.spacing24};
    }
`;

const Title = styled.span`
    ${NewLongParagraph};
    font-weight: 500;
    margin-bottom: ${({ theme }) => theme.spacing8};
    display: block;
    color: ${({ theme }) => theme.neutral0};
    text-align: left;

    @media (min-width: ${({ theme }) => theme.mediumMinWidth}) {
        text-align: center;
        margin-top: 0;
    }
`;

const LinksList = styled.ul`
    display: block;
    margin: ${({ theme }) => theme.spacing24} 0 0;

    @media (min-width: ${({ theme }) => theme.largeMinWidth}) {
        margin-top: 0;
        display: flex;
        justify-content: center;
    }
`;

const ListItem = styled.li`
    margin-bottom: ${({ theme }) => theme.spacing12};

    @media (min-width: ${({ theme }) => theme.mediumMinWidth}) {
        margin-right: ${({ theme }) => theme.spacing16};
        margin-bottom: 0;

        :last-child {
            margin-right: 0;
        }
    }
`;

const TextWrapper = styled.div`
    display: flex;
    flex-direction: column;
    margin: ${({ theme }) => theme.spacing16} 0;

    @media (min-width: ${({ theme }) => theme.largeMinWidth}) {
        flex-direction: row;
        margin: ${({ theme }) => theme.spacing24} 0;
    }
`;

const ColumnOne = styled.div`
    @media (min-width: ${({ theme }) => theme.largeMinWidth}) {
        margin-right: ${({ theme }) => theme.spacing24};
    }
`;

const ColumnTwo = styled.div``;

const Paragraph = styled.p`
    ${ShortParagraphCss};
    margin-bottom: ${({ theme }) => theme.spacing8};
    max-width: 720px;
    color: ${({ theme }) => theme.primary50};

    :last-child {
        margin-bottom: 0;
    }
`;

const IntercomInlineLink = styled.button`
    ${NewLongParagraph};
    background: transparent;
    border: none;
    padding: 0;
    color: ${({ theme }) => theme.action500};
    cursor: pointer;
    font-weight: 700;
`;

const LinkText = styled(Link)`
    ${ShortParagraphCss};
    color: ${({ theme }) => theme.action500};
    text-decoration: none;
    font-weight: 700;
    cursor: pointer;
    background: transparent;
    border: none;
    padding: 0;
`;

const LogosWrapper = styled.div`
    display: flex;
    padding-bottom: ${({ theme }) => theme.spacing24};
    justify-content: flex-start;
    align-items: center;

    @media (min-width: ${({ theme }) => theme.largeMinWidth}) {
        justify-content: flex-end;
        padding-bottom: ${({ theme }) => theme.spacing40};
    }
`;
